import Pone from '~image/portfolio/portfolio-full-img-1.jpg'
import Ptwo from '~image/portfolio/portfolio-full-img-2.jpg'
import Pthree from '~image/portfolio/portfolio-full-img-3.jpg'
import Pfour from '~image/portfolio/portfolio-full-img-4.jpg'
import Pfive from '~image/portfolio/portfolio-full-img-6.jpg'
import Psix from '~image/portfolio/portfolio-full-img-7.jpg'
import Pseven from '~image/portfolio/portfolio-full-img-8.jpg'
import Peight from '~image/portfolio/portfolio-full-img-9.jpg'
const portfolioData = [
    {
        image:'https://res.cloudinary.com/dv10simqm/image/upload/v1642105992/helios/Broker%20de%20seguros/latino_2x_tdsu8a.png',
        titleSmall:"Agency Brand guide",
        title:"UX and UI, Design",
        id:"1",
        tagName: ["all","design","seo"]
    },
    {
        image:'https://res.cloudinary.com/dv10simqm/image/upload/v1642105991/helios/Broker%20de%20seguros/gnp_2x_fobqpr.png',
        titleSmall:"Agency Brand guide",
        title:"UX and UI, Design",
        id:"2",
        tagName: ["all","marketing","development"]
    },
    {
        image:'https://res.cloudinary.com/dv10simqm/image/upload/v1642105991/helios/Broker%20de%20seguros/hdi_2x_ot3uc5.png',
        titleSmall:"Agency Brand guide",
        title:"UX and UI, Design",
        id:"3",
        tagName: ["all","design","marketing"]
    },
    {
        image:'https://res.cloudinary.com/dv10simqm/image/upload/v1642105991/helios/Broker%20de%20seguros/ana_2x_abjuq5.png',
        titleSmall:"Agency Brand guide",
        title:"UX and UI, Design",
        id:"4",
        tagName: ["all","seo"]
    },
    {
        image:'https://res.cloudinary.com/dv10simqm/image/upload/v1642105991/helios/Broker%20de%20seguros/atlas_2x_s7teyn.png',
        titleSmall:"Agency Brand guide",
        title:"UX and UI, Design",
        id:"6",
        tagName: ["all","design"]
    },
    {
        image:'https://res.cloudinary.com/dv10simqm/image/upload/v1642105992/helios/Broker%20de%20seguros/qualitas_2x_b3aurt.png',
        titleSmall:"Agency Brand guide",
        title:"UX and UI, Design",
        id:"6",
        tagName: ["all","design"]
    },
    {
        image:'https://res.cloudinary.com/dv10simqm/image/upload/v1642105992/helios/Broker%20de%20seguros/zurich_2x_vh7sxw.png',
        titleSmall:"Agency Brand guide",
        title:"UX and UI, Design",
        id:"6",
        tagName: ["all","design"]
    },
    {
        image:'https://res.cloudinary.com/dv10simqm/image/upload/v1642105991/helios/Broker%20de%20seguros/gs_2x_btks90.png',
        titleSmall:"Agency Brand guide",
        title:"UX and UI, Design",
        id:"6",
        tagName: ["all","design"]
    },
    {
        image:'https://res.cloudinary.com/dv10simqm/image/upload/v1642105991/helios/Broker%20de%20seguros/elaguila_2x_rgmhhy.png',
        titleSmall:"Agency Brand guide",
        title:"UX and UI, Design",
        id:"6",
        tagName: ["all","design"]
    }
]
export default portfolioData;